<template>
  <div
    class="breadcrumb"
    :class="theme"
  >
    <div
      v-for="({ item, name }, index) in props.list"
      :key="index"
      class="item"
    >
      <NuxtLink
        v-if="index < list.length - 1"
        :to="item"
      >
        {{ name }}
      </NuxtLink>
      <p v-else>
        {{ name }}
      </p>
      <UiComponentIcon
        v-if="index < list.length - 1"
        type="chevron-right"
      />
    </div>
  </div>
</template>

<script setup>
// Props
const props = defineProps({
  list: {
    required: true,
    type: Array,
  },
  theme: {
    default() {
      return 'light'
    },
    required: false,
    type: String,
    validator(value) {
      return ['dark', 'light'].indexOf(value) !== -1
    },
  },
})
</script>

<style lang="postcss" scoped>
.breadcrumb {
  @apply inline-block items-center min-h-[2.5rem] w-full mt-20;

  @screen md {
    @apply mt-34;
  }

  &.dark {
    .item {
      a,
      p,
      i {
        @apply text-[#CED0D0];
      }

      a {
        &:hover {
          @apply text-white;
        }
      }

      p {
        @apply text-white;
      }
    }
  }

  &.light {
    .item {
      a,
      p,
      i {
        @apply text-grey-blue-600;
      }

      a {
        &:hover {
          @apply text-livestorm-blue-600;
        }
      }

      p {
        @apply text-winter-green-900;
      }
    }
  }

  .item {
    @apply inline items-center ml-2;

    &:first-child {
      @apply ml-0;
    }

    a,
    p,
    i {
      @apply inline text-xs leading-4;
    }

    a {
      @apply transition-colors duration-500 ease-in-out;
    }

    i {
      @apply ml-2 text-[10px];
    }
  }
}
</style>
